import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Cookie from "js-cookie";

import Button from 'react-bootstrap/Button';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useUpdateCheck } from 'react-update-notification';

import CookieConsent from "react-cookie-consent";

import { useTranslation } from "react-i18next";

import 'assets/base.scss';

import { loadMe } from 'redux/actions/auth';

import { ScrollTop } from 'components/scroll';
import renderRoutes, { URLS } from 'router';
import { UserContext } from 'contexts';
import { isTokenValid } from 'utils/auth';
import { SayError } from 'components/SayError';

const NotificationContainer = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 24 * 60 * 60 * 1000, // 1day
  });
  console.log(status)

  if (status === 'checking' || status === 'current') {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute', left: '1rem', bottom: '4rem', zIndex: 9999
      }}
    >
      <Button
        variant="default"
        onClick={reloadPage}
        style={{
          maxWidth: '140px',
          backgroundImage: 'linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%)',
        }}
      >
        Refresh to update the app
      </Button>
    </div>
  );
};

export const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user,
    loading,
    setCurrentUser,
    setLoading,
  } = React.useContext(UserContext);

  React.useEffect(() => {
    if (!user?.email && loading === false && window.location.pathname !== URLS.LOGIN) {
      setLoading(true);
      const searchParams = new URLSearchParams();
      if (window?.location?.pathname?.startsWith(URLS.COMPETITION.LIST)) {
        searchParams.append('next', encodeURI(window.location.pathname))
      }

      if (!isTokenValid()) {
        Cookie.remove('token');
        navigate({
          pathname: URLS.LOGIN,
          search: searchParams.toString(),
        });
        return;
      }
      dispatch(loadMe()).then(
        r => {
          if (r.payload.status === 200) {
            setCurrentUser(r.payload.data);
          } else {
            Cookie.remove('token');
            navigate({
              pathname: URLS.LOGIN,
              search: searchParams.toString(),
            });
            return;
          }
        },
        () => {
          Cookie.remove('token');
          navigate({
            pathname: URLS.LOGIN,
            search: searchParams.toString(),
          });
          return;
        }
      );
    }
  }, [dispatch, loading, navigate, setLoading, user, setCurrentUser])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdxFOMnAAAAADSJQzK3Tvafm90ZrPqNK03S87LW"
      language="ru"
      scriptProps={{
        async: true,
        appendTo: 'body',
      }}
    >
      <CookieConsent
        location="bottom"
        style={{ alignItems: 'center', background: "#2B373B" }}
        buttonStyle={{
          background: "#2B373B",
          color: "white",
          border: "white 1px solid"
        }}
        buttonText={t('accept')}
        expires={150}
      >
        <h3>Сайт использует файлы cookie</h3>
        <p>Сайт использует файлы cookie, которые позволяют узнавать вас и получать информацию о вашем пользовательском опыте.</p>
        <p>Разрешение на использование cookie необходимо для использования Сайта и его сервисов, включая заказ услуг. Посещая страницы сайта, мы уверены, что вы даете согласие на использование и хранение файлов cookie на вашем устройстве. Если согласны, продолжайте пользоваться сайтом. Если нет – установите специальные настройки в браузере или обратитесь в техподдержку.</p>
      </CookieConsent>
      <div className="content-wrapper">
        <NotificationContainer />
        {
          renderRoutes()
        }
        <ScrollTop />
        <SayError />
      </div>
    </GoogleReCaptchaProvider>
  );
};

