import Cookie from "js-cookie";

export const getToken = () => {
  return Cookie.get("token") || null;
};

export const getAuthHeader = (token) => {
  return 'Bearer ' + (token || getToken());
};

const b64DecodeUnicode = (str) => {
  return decodeURIComponent(
    window.atob(str).replace(/(.)/g, function (m, p) {
      let code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = "0" + code;
      }
      return "%" + code;
    })
  );
}

const base64_url_decode = (str) => {
  let output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw new Error("base64 string is not of the correct length");
  }

  try {
    return b64DecodeUnicode(output);
  } catch (err) {
    return window.atob(output);
  }
}

const InvalidTokenError = (message) => {
  this.message = message;
}

InvalidTokenError.prototype = new Error();
InvalidTokenError.prototype.name = "InvalidTokenError";

const jwtDecode = (token, options) => {
  if (typeof token !== "string") {
    throw new InvalidTokenError("Invalid token specified: must be a string");
  }

  const _options = options || {};
  const pos = _options.header === true ? 0 : 1;

  const part = token.split(".")[pos];
  if (typeof part !== "string") {
    throw new InvalidTokenError(
      "Invalid token specified: missing part #" + (pos + 1)
    );
  }

  let decoded = null;
  try {
    decoded = base64_url_decode(part);
  } catch (e) {
    throw new InvalidTokenError(
      "Invalid token specified: invalid base64 for part #" + (pos + 1) + ' (' + e.message + ')'
    );
  }

  try {
    return JSON.parse(decoded);
  } catch (e) {
    throw new InvalidTokenError(
      "Invalid token specified: invalid json for part #" + (pos + 1) + ' (' + e.message + ')'
    );
  }
}

export const isTokenValid = (token) => {
  const _token = token || getToken();
  if (!_token) {
    return false;
  }

  const decoded = jwtDecode(_token);
  if (!decoded?.exp) {
    return false;
  }

  const now_timestamp = Math.floor(Date.now() / 1000);
  return (decoded.exp - now_timestamp) > 10
}