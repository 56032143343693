import React from 'react';

export const SayError = () => {
  return (
    <div
      className="d-flex align-items-center jc-sb p-3"
      style={{ marginBottom: "5rem" }}
    >
      <a href="https://t.me/ftbspbBot" target="blank" >Сообщить об ошибке</a>
    </div>
  );
};