import axios from 'axios';
import Cookie from "js-cookie";

import { API_URLS } from './urls';

export const getToken = () => {
  const _token = Cookie.get("token");

  if (!_token) {
    return;
  }

  return _token;
};

export const getLang = (lang) => {
  return axios.get(API_URLS.translation.competition, {
    params: {
      lang
    }
  });
};