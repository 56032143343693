// import axios from 'axios';
// import Cookie from "js-cookie";

import { SET_LANG } from 'redux/actions';
/*
import {
  GET_ME,
  GET_TOKEN,
  SET_USER,
  USER_LOGOUT,
} from 'redux/actions/auth';
 */
import {
  // SET_COMPETITION,
  FETCH_COMPETITION_LIST,
  // FETCH_COMPETITION_ONE,
  // FIND_COMPETITION_PARTICIPANT,
  // CREATE_COMPETITION_PARTICIPANT,
  // CREATE_COMPETITION_PARTICIPANT_FILE,
} from 'redux/actions/competition';
// import { DELETE_CUMF, FETCH_MEDIA_LIST } from 'redux/actions/media';

// import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'core/settings';
import { SUCCESS_SUFFIX } from 'core/settings';

/*
const competition = (state = null, action) => {
  switch (action.type) {
    case SET_COMPETITION:
      return action?.data;
    case FETCH_COMPETITION_ONE + SUCCESS_SUFFIX:
      return action?.payload?.data;
    default:
      return state;
  }
};
*/

const lang = (state = null, action) => {
  switch (action.type) {
    case SET_LANG:
      return action.data;
    default:
      return state;
  }
};

/*
const media = (state = null, action) => {
  switch (action.type) {
    case FETCH_MEDIA_LIST + SUCCESS_SUFFIX:
      return {
        ...state,
        files: action?.payload?.data,
        mediaLoaded: true,
        mediaLoading: false,
      };
    case FETCH_MEDIA_LIST:
      return {
        ...state,
        mediaLoading: true,
      };
    default:
      return state
  }
};
*/

const competitions = (state = null, action) => {
  switch (action.type) {
    case FETCH_COMPETITION_LIST + SUCCESS_SUFFIX:
      return action?.payload?.data || [];
    default:
      return state;
  }
};

/*
const participant = (state = null, action) => {
  switch (action.type) {
    case CREATE_COMPETITION_PARTICIPANT + SUCCESS_SUFFIX:
      return action?.payload?.data;
    case FIND_COMPETITION_PARTICIPANT + SUCCESS_SUFFIX:
      if (action?.payload?.data && action?.payload?.data.length > 0) {
        return action?.payload?.data[0];
      }
      return {
        phone: '',
        current_weight: 0,
        gender: 2,
        rate: 1,
        status: 2,
        email: action?.payload?.config?.params?.email
      }
    case DELETE_CUMF + SUCCESS_SUFFIX:
      const cumf = state.cumf.filter(
        (item) => item.id !== parseInt(action.payload.config.data)
      );
      return {
        ...state,
        cumf
      };
      case CREATE_COMPETITION_PARTICIPANT_FILE + SUCCESS_SUFFIX:
      return {
        ...state,
        cumf: [
          ...state?.cumf || [],
          action.payload.data
        ]
      };
    case USER_LOGOUT + SUCCESS_SUFFIX:
      return {
        phone: '',
        current_weight: 0,
        gender: 2,
        rate: 1,
        status: 2,
      };
    default:
      return state;
  }
};
*/
/*
const user = (state = null, action) => {
  switch (action.type) {
    case GET_ME + SUCCESS_SUFFIX:
      return action?.payload?.data;
    case GET_ME + ERROR_SUFFIX:
      Cookie.remove('token');
      return null;
    case SET_USER:
      return action.data;
    case USER_LOGOUT + SUCCESS_SUFFIX:
      Cookie.remove('token');
      return null;
    case GET_TOKEN + SUCCESS_SUFFIX:
      const token = action?.payload?.data?.result?.token;
      // TODO: move to middleware
      if (token) {
        Cookie.set('token', token || null);
        axios.defaults.headers.common.authorization = 'Bearer ' + token;
      }
      return state;
    default:
      return state;
  }
};
*/

export {
  // competition,
  competitions,
  lang,
  // media,
  // participant,
  // user
};