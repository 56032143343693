import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from "@sentry/browser";

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import browserHistory from 'router/history';
import { syncHistoryWithStore } from 'react-router-redux';

import 'assets/base.scss';
import { App } from 'App';
import "core/i18n";
import { DefaultPageLoader } from 'components/loaders';
import { LOAD_STATUSES } from 'core/settings';
import { UserProvider } from 'providers';
import { store } from 'redux/store';

import reportWebVitals from './reportWebVitals';

const history = syncHistoryWithStore(browserHistory, store);

const root = ReactDOM.createRoot(document.getElementById("is-root"));

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: "https://309c6f0786e6416db5872cb9d5fae60f@app.glitchtip.com/8474" });
}

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<DefaultPageLoader load={LOAD_STATUSES.LOADING} />}>
      <Provider store={store}>
        <BrowserRouter location={history.location} navigator={history}>
          <UserProvider>
            <App />
          </UserProvider>
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
