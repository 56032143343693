import { LOAD_STATUSES } from 'core/settings';

export const InputLoader = ({ load }) => {
  if (load !== LOAD_STATUSES.LOADING) {
    return;
  }
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        position: 'absolute',
        top: '0',
        right: '0',
        width: '2rem',
        height: 'calc(100% - 0.4rem)',
        backgroundColor: 'rgb(233, 236, 239)',
        margin: '0.2rem'
      }}
    >
      <div
        className="spinner-border spinner-border-sm text-warning"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
  );
}

export const DefaultPageLoader = ({ load }) => {
  if (load !== LOAD_STATUSES.LOADING) {
    return;
  }
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        width: '100%',
        height: '50vw',
      }}
    >
      <div
        className="spinner-border spinner-border-sm text-primary"
        style={{
          width: '5rem',
          height: '5rem',
          fontSize: '1.5rem'
        }}
      ></div>
    </div>
  );
}