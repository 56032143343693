export const API_URLS = {
  competition: {
    list: '/api/v1/competition/',
    participant: {
      list: '/api/v1/competition-participant/',
      current: '/api/v1/competition-participant/current/'
    },
    participantFiles: {
      list: '/api/v1/competition-participant-file/'
    },
  },
  media: {
    list: '/api/v1/media/'
  },
  translation: {
    competition: '/api/v1/translation/all.json'
  },
  user: {
    auth: '/api/v1/auth/',
    me: '/api/v1/auth/user/me/',
    login: '/api/v1/auth/token/',
    logout: '/api/v1/auth//logout/',
    token: '/api/v1/auth/token/',
  }
}