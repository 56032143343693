import React from "react"
import { useNavigate } from 'react-router-dom';

import Cookie from "js-cookie";

import { UserContext } from "contexts"
import { URLS } from 'router';

const defaultUser = {
  id: null,
  usename: '',
  email: '',
  is_active: false
}

export const UserProvider= ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = React.useState(defaultUser);
  const [loading, setLoading] = React.useState(false);

  const setCurrentUser = (newUser) => {
    setUser(newUser);
    setLoading(false);
    if (newUser === null) {
      Cookie.remove('token');
      navigate(URLS.LOGIN);
    }
  }

  return (
    <UserContext.Provider value={{ user, setCurrentUser, loading, setLoading }}>
      {children}
    </UserContext.Provider>
  );
}