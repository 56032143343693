import { API_URLS } from 'api/urls';
// import { config } from 'core/settings';
import { makeDispatch } from 'redux/utils';
import { getToken } from "utils/auth";

export const SET_USER = 'SET_USER';
export const GET_ME = 'GET_ME';
export const GET_TOKEN = 'GET_TOKEN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SIGNIN = 'USER_SIGNIN';

export const setUser = (data) => {
  return {
    type: SET_USER,
    data
  }
};

export const loadMe = () => (dispatch, getState) => {
  const token = getToken();
  if (!token) {
    return;
  }
  return makeDispatch({
    data: null,
    dispatch,
    headers: {
      Authorization: 'Bearer ' + token
    },
    method: 'GET',
    params: null,
    state: getState(),
    token,
    type: GET_ME,
    url: API_URLS.user.me
  });
};

export const token = (params) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: GET_TOKEN,
    url: API_URLS.user.login
  });
};

export const login = (data) => (dispatch, getState) => {
  return makeDispatch({
    data,
    dispatch,
    method: 'POST',
    params: null,
    state: getState(),
    type: USER_SIGNIN,
    url: API_URLS.user.login
  });
};
