import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';

import { API_URLS } from "api/urls";
import { config } from "./settings";

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      allowMultiLoading: false,
      crossDomain: true,
      loadPath: config.host + API_URLS.translation.competition + '?lang={{lng}}'
    },
    lng: 'ru',
    fallbackLng: 'en',
    whitelist: ['en', 'ru']
  });

export default i18next;
