import axios from 'axios';

import localforage from 'localforage';

import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import axiosMiddleware from 'redux-axios-middleware';

import { getToken } from 'api/settings';
import { config, initialState } from 'core/settings';
import { asyncDispatchMiddleware } from 'redux/middleware/asyncMiddleware';
import * as reducers from 'redux/reducers';
import history from 'router/history';

//axios
axios.defaults.baseURL = config.host;

const token = getToken();
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token;
}
const client = axios.create({
  baseURL: config.host,
  responseType: 'json'
});
const errorOption = { returnRejectedPromiseOnError: true };

// local storage
const saveStateToStorage = (state) => {
  try {
    const serialisedState = JSON.stringify(state);
    const oldState = localforage.getItem(config.storageName);
    if (oldState === serialisedState) return;
    localforage.setItem(config.storageName, serialisedState);
  } catch (e) {
    console.warn(e);
  }
};

const loadStateFromStorage = async () => {
  try {
    const serialisedState = await localforage.getItem(config.storageName);
    if (serialisedState === null) return initialState;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return initialState;
  }
};

// reducers
const rootReducer = combineReducers({ ...reducers, routing: routerReducer });

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  await loadStateFromStorage(),
  composeEnhancer(
    applyMiddleware(
      axiosMiddleware(client, errorOption),
      thunkMiddleware,
      routerMiddleware(history),
      asyncDispatchMiddleware
    ),
  )
);
store.subscribe(() => saveStateToStorage(store.getState()));