import React, { lazy, Suspense } from 'react';
import { Routes, Route } from "react-router-dom";

import { DefaultPageLoader } from 'components/loaders';
import { LOAD_STATUSES } from 'core/settings';


const Login = lazy(() => import('pages/Login'));
const Logout = lazy(() => import('pages/Logout'));
const Home = lazy(() => import('pages/Home'));
const CompetitionParticipantSignIn = lazy(() => import(
  'pages/competition/CompetitionParticipantSignIn'
));

export const URLS = {
  COMPETITION: {
    LIST: '/competition',
    DETAIL: '/competition/:guid',
    PARTICIPANT: {
      SIGNIN: '/competition/:guid/sign-in'
    },
  },
  ROOT: '/',
  HOME: '/',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
}

const renderRoutes = () => (
  <Suspense fallback={<DefaultPageLoader load={LOAD_STATUSES.LOADING} />}>
    <Routes>
      <Route exact path={URLS.LOGIN} element={<Login />} is_lazy />
      <Route exact path={URLS.LOGOUT} element={<Logout />} is_lazy />
      <Route exact path={URLS.ROOT} element={<Home />} is_lazy />
      <Route exact path={URLS.HOME} element={<Home />} is_lazy />
      <Route path={URLS.COMPETITION.LIST}>
        <Route
          exact
          path=":guid/sign-in"
          element={<CompetitionParticipantSignIn />}
          is_lazy
        />

      </Route>
    </Routes>
  </Suspense>
);

export default renderRoutes;